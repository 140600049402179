<div>
  <mat-form-field>
      <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="e.g. Redmi Note 8 Pro" #input>
  </mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
      <td mat-cell *matCellDef="let item"> {{item.id}} </td>
    </ng-container>
 -->
 <!-- Name Column -->
 <ng-container matColumnDef="name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
  <td mat-cell *matCellDef="let item"> {{item.name}} </td>
</ng-container>

   <!-- ID Column -->
   <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
    <td mat-cell *matCellDef="let item"> {{item.category}} </td>
  </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost </th>
      <td mat-cell *matCellDef="let item"> {{item.cost | currency:'USD':'symbol'}} </td>
    </ng-container>
 
    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity Order</th>
      <td mat-cell *matCellDef="let item"> {{item.quantity}} </td>
    </ng-container>

   <!-- Quantity Column -->
   <ng-container matColumnDef="quantitySold">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity sold</th>
    <td mat-cell *matCellDef="let item"> {{item.quantitySold}} </td>
  </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let item">
        <app-item-actions [item]="item"></app-item-actions>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let item; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No items found!</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div>
