import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {ItemsComponent} from './public/items/items.component';
import {AddItemComponent} from './public/add-item/add-item.component';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import { SalesComponent } from './public/sales/sales.component';
import { CategoryComponent } from './public/category/category.component';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { ItemMenuComponent } from './public/item-menu/item-menu.component';
import { HomeComponent } from './public/home/home.component';
import { AddCategoryComponent } from './public/add-category/add-category.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'items',
    component: ItemMenuComponent,
  },
  {
    path: 'item/add',
    component: AddItemComponent,
  },
 
  {
    path: 'sales',
    component: SalesComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },

  {
    path: 'category',
    component: CategoryComponent,
  }, 
  {
    path: 'category/add',
    component: AddCategoryComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  }, 
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
