import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, Role } from '../auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  signUpFrom!:FormGroup;
  validationErrors:Array<any> = []
  roles: Observable<string[]>= new Observable;


  constructor(private authService: AuthService,private fb:FormBuilder, private router: Router,
    private _snackBar: MatSnackBar,private dialogRef: MatDialogRef<RegisterComponent>,) { }

  ngOnInit(): void {
    this.signUpFrom=this.fb.group({
     username: new FormControl('',[Validators.required,Validators.minLength(3),Validators.maxLength(20)]),
     role:new FormControl ('',[Validators.required]),
     email:new FormControl('',[Validators.required,Validators.email]),
     password:new FormControl ('',[Validators.required,Validators.minLength(6)])
   });
   this.roles=this.authService.getRoles();

}
get f(){
  return this.signUpFrom.controls;
}

onSubmit(data:any){
  if(this.signUpFrom.valid){ 
    this.authService.register(this.signUpFrom.value).subscribe(
      data => {
        console.log(data);
        this.router.navigate(['/home']);
        this.openSnackBarSuccesMessage(data.message);
        this.close();

      },
      (error:HttpErrorResponse) => {
        this.openSnackBarErrorMessage(error.error)
      
      }
    );
  }
}

close() {
  this.dialogRef.close();
}

openSnackBarSuccesMessage(message:string) {
 // let message='Registration done! <br> please login with your credential infos';

  this._snackBar.open(message, 'Ok',{ horizontalPosition: 'start',panelClass: 'sucess' });
}
openSnackBarErrorMessage(error: string) {
  //let message='Registration Failed !';

  this._snackBar.open(error, 'Close',{ horizontalPosition: 'start',panelClass: 'fail' });
}

}
