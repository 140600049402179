import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BACKEND } from 'src/app/config';
import { Category } from 'src/app/interfaces/Category';
import { CategoryService } from 'src/app/services/category.service';
import { CategoryDetailsComponent } from '../category-details/category-details.component';

@Component({
  selector: 'app-category-update',
  templateUrl: './category-update.component.html',
  styleUrls: ['./category-update.component.scss']
})
export class CategoryUpdateComponent implements OnInit {

  @Input() category!: Category;
  constructor(
    private matDialog: MatDialog,
    private categoryService: CategoryService
  ) { }

  ngOnInit(): void {
  }
  
  onUpdate(): void {
    const dialogRef = this.openDialog();
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.updateCategory(result);
      }
    });
  }

  private openDialog(): MatDialogRef<any> {
    const category: Category = Object.assign({}, this.category);
    return this.matDialog.open(CategoryDetailsComponent, {
      data: {
        category,
        categoryActionTitle: 'Update Category',
        submitButtonLabel: 'Update',
        readonlyFields: {
          name: false,
          comment: false,
        }
      }
    });
  }

  private updateCategory(result: Category): void {
    // Todo Use HATEOAS Urls
    const url = BACKEND.HOST + '/api/categories/' + this.category.id;
    const category: Category = {
      id: undefined,
      name: result.name,
     comment:result.comment,
    };
    this.categoryService.updateCategory(category)
      .subscribe();
  }
}
