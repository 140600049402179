<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" >
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <h1 class="example-app-name">
    </h1>
    <div >
      <img class="icon"
       width="55" src="assets/images/logo.ico" />
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"[fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
          <mat-nav-list>
            <mat-list-item *ngFor="let nav of fillerNav">
              <mat-icon>{{nav.matIcon}}</mat-icon>
              <a 
                [routerLink]="nav.routerLink"
                [routerLinkActive]="['is-active']">{{nav.name}}
              </a>
            </mat-list-item>
          </mat-nav-list>
                 
        </mat-sidenav>
    <mat-sidenav-content >
        <router-outlet >
        </router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
