import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import {FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenStorageService } from '../token-storage.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { color } from 'd3';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoggedIn = false;
  isLoginFailed = false;
  roles: string[] = [];
  signInFrom!:FormGroup;
  hide = true;
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('',[Validators.required,Validators.minLength(6),Validators.maxLength(20)])

  constructor(
    private authService: AuthService, private tokenStorage: TokenStorageService,
    private fb:FormBuilder, private dialogRef: MatDialogRef<LoginComponent>, 
    private router: Router,private _snackBar: MatSnackBar,
   ) { }

  ngOnInit(): void {
    
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
    }
    this.signInFrom= this.fb.group({ usernameOrEmail:this.email, password: this.password }); 

  }
  get f(){
    return this.signInFrom.controls;
  } 

  onSubmit(data:any): void {
    if(this.signInFrom.valid){ 
      this.authService.login(this.signInFrom.value).subscribe(
        data => {
          this.tokenStorage.saveToken(data.accessToken!);
          this.tokenStorage.saveUser(data.user);
  
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.router.navigateByUrl('items')
          this.close();
        },
        (err:HttpErrorResponse) => {
         this.openSnackBar(err.error.message)
          this.isLoginFailed = true;
        }
      );
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message,'',{
      duration: 6000,
      panelClass: "fail",
      verticalPosition: "top",
      horizontalPosition: "center",

    });
  }
  reloadPage(): void {
    window.location.reload();
  }
  getErrorMessage() {
    if (this.email.hasError('required')|| (this.password.hasError('required'))) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

   close() {
        this.dialogRef.close();
    }
}