import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sales } from '../interfaces/Sales';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ObservableInput, of } from 'rxjs';
import { ItemEventListenerService } from './item-event-listener/item-event-listener.service';
import { BACKEND } from '../config';


@Injectable({
  providedIn: 'root'
})
export class SaleService {

  url = BACKEND.HOST + '/api/items/itemActions';
  constructor(
    private http: HttpClient,
    private itemEventListenerService: ItemEventListenerService
    ) { }

    
  getSales(startDate:string, endDate:string):Observable<Sales[]>{
    let params = new HttpParams().set("startDate", startDate).set("endDate",endDate);
    return this.http.get<Sales>(this.url,{params:params})
      .pipe(
        
        catchError(error => this.handleError(error))
      );
  }
  handleError(error: any): ObservableInput<any> {
    console.log(error);
    this.itemEventListenerService.onFailure(error);
    return of([]);
  }
}
