import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from 'src/app/interfaces/item';

@Component({
  selector: 'app-sale-details-form',
  templateUrl: './sale-details-form.component.html',
  styleUrls: ['./sale-details-form.component.scss'],
})
export class SaleDetailsFormComponent implements OnInit {
  initialValue='select category';
  readonlyFields: {
    code?: boolean;
    category?:boolean;
    name?: boolean,
    cost?: boolean,
    quantity?: boolean
  } = {};
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      item: Item,
      itemActionTitle: string,
      submitButtonLabel: string,
      readonlyFields: {
        code?: boolean;
        category?:boolean;
        name?: boolean,
        price?:boolean,
        cost?: boolean,
        quantity?: boolean
      }
    }
  ) { }

  
  textInputValidators = [Validators.required, Validators.maxLength(100)];
  currencyInputValidators = [Validators.required, Validators.pattern(/^\d+(.\d{2})?$/)];
  numberInputValidators = [Validators.required, Validators.pattern(/^\d+$/)];

  itemForm = this.formBuilder.group({
    code: [this.data.item.code, this.textInputValidators],
    category: [this.data.item.category,  Validators.maxLength(100)],
    name: [this.data.item.name, this.textInputValidators],
    cost: [this.data.item.cost, this.currencyInputValidators],
    price: [this.data.item.price, this.currencyInputValidators],
    quantity: [this.data.item.quantity, this.numberInputValidators]
  });
  ngOnInit(): void {}
  
}
