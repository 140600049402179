import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { RegisterComponent } from '../security/register/register.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MenuItem } from 'src/app/interfaces/menu-item';
import { LoginComponent } from '../security/login/login.component';
import { IvyParser } from '@angular/compiler';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent{
  visible: boolean = false;
  constructor(public dialog: MatDialog) {
  }
  menuItems: MenuItem[] = [
    {
      name: "Sign Up",
      matIcon: "login",
    },
    {
      name: "About",
      matIcon: "help",
    }
  ]

showDialog(action:string){
  const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
  if(action==="register"){
    this.dialog.open(RegisterComponent,{ 
      autoFocus: false,
                    width: '460px',
                     height: '380px',
                    panelClass: 'custom-dialog-container'}
    );
  }
  if(action==='login'){
    this.dialog.open(LoginComponent,{ 
      autoFocus: false,
                    width: '460px', 
                    height: '380px',
                    panelClass: 'custom-dialog-container'}
    );
  }


}
}
