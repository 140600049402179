import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CategoryResponse } from 'src/app/interfaces/Category-response';
import { Sales } from 'src/app/interfaces/Sales';
import { SaleService } from 'src/app/services/sale.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements AfterViewInit {
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort
 
  displayedColumns: string[] = ['code', 'name', 'date','price','quantity'];
  private sales: Sales[] = [];

  dataSource = new MatTableDataSource<Sales>(this.sales);

  constructor(private saleService: SaleService,private formBuilder:FormBuilder) { }

  ngAfterViewInit(): void {
    this.getSales('','');
  }

  
  dateForm= this.formBuilder.group({
    startDate: ['',[Validators.required]],
    endDate: ['', [Validators.required]]
  },
  {validators:this.customValidatorGroup()}); 
  currentDate = this.dateForm.get('startDate')?.value;
  priviousDate = this.dateForm.get('endDate')?.value;

  customValidatorGroup()
  {
    return (form:FormGroup)=>{
        const min=form.get('fomDate');
        const max=form.get('toDate');
        return min?.value && max?.value && max.value.toDate()<min.value.toDate()?{error:'min malue'}:null
    }
  }

  getSales(start:string, end:string): void {
    this.saleService.getSales(start, end)
      .subscribe((allSales: Sales[]) => {
        this.sales = allSales;
        this.updateTableDataSource();
      });
  }

  updateTableDataSource(): void {
    this.dataSource = new MatTableDataSource<Sales>(this.sales);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
