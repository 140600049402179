import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/interfaces/Category';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {

  readonlyFields: {
    id?:true,
    name?: boolean,
    comment?: boolean,
  } = {};


  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      category: Category,
      categoryActionTitle: string,
      submitButtonLabel: string,
      readonlyFields: {
        id:boolean,
        name?: boolean,
        comment?: boolean,
      }
    }
  ) { }
  textInputValidators = [Validators.required, Validators.maxLength(100)];

  categoryForm = this.formBuilder.group({
    id: [this.data.category.id, this.textInputValidators],
    name: [this.data.category.name, this.textInputValidators],
    comment: [this.data.category.comment, Validators.maxLength(100)],
  });
  ngOnInit(): void {
  }

}
