import { Injectable } from '@angular/core';
import { Category } from '../interfaces/Category';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryEventListenerService {

  constructor() { }

  private categoryEventAddInputSource = new Subject<Category>();
  private categoryEventAddEmitSource = new Subject<Category>();
  private categoryEventFailureEmitSource = new Subject<any>();
  private categoryEventDeleteEmitSource = new Subject<any>();
  private categoryEventUpdateEmitSource = new Subject<Category>();



  categoryEventAddInput$ = this.categoryEventAddInputSource.asObservable();
  categoryEventAddEmit$ = this.categoryEventAddEmitSource.asObservable();
  categoryEventFailureEmit$ = this.categoryEventFailureEmitSource.asObservable();
  itemEventDeleteEmit$ = this.categoryEventDeleteEmitSource.asObservable();
  itemEventUpdateEmit$ = this.categoryEventUpdateEmitSource.asObservable();

  onAdd(category: Category): void {
    this.categoryEventAddInputSource.next(category);
  }

  onAdded(category: Category): void {
    this.categoryEventAddEmitSource.next(category);
  }

  onFailure(error: any): void {
    this.categoryEventFailureEmitSource.next(error);
  }
  onDeleted(): void {
    this.categoryEventDeleteEmitSource.next();
  }
  onUpdated(category: Category): void {
    this.categoryEventUpdateEmitSource.next(category);
  }


}
