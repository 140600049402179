<mat-toolbar color="primary">New User</mat-toolbar>
  <br><br>
<div class="col-md-12">
  <div class="card card-container">

      <form
      class="form-card"
      [formGroup]="signUpFrom" 
        (ngSubmit)="onSubmit(signUpFrom.value)">
    <div> 
       <mat-dialog-content>

  <div class="form-group">
         <div>
              <label class="form-control-label px-3" for="username">User name<span class="text-danger"> *</span></label> 
              <span *ngIf="f['username'].touched && f['username'].hasError('required')" class="form-error-message">
             <b>User name is required</b> 
             </span>   
     
              <span *ngIf="f['username'].hasError('minlength')" class="form-error-message">
             <b>User name require at least 3 characters.</b> 
             </span>   
             <span *ngIf="f['username'].hasError('maxlength')" class="form-error-message">
             <b>User name require at most 25 characters.</b> 
              </span>
          
          <input
            type="text"
            class="form-control"
            name="username"
            formControlName="username"
            placeholder="user's name"
          /> 
        </div>  
                <br>
  </div>
  <div class="form-group">

    <label for="role">Select user's role<span class="text-danger"> *</span></label>
    <span *ngIf="f['role'].touched && f['role'].hasError('required')" class="form-error-message">
      <b>!role is required</b> 
   </span> 

    <select formControlName="role" required>
      <option *ngFor="let role of (roles | async)" 
      [ngValue]="role">{{ role }}
    </option>
    </select>
   
</div>
<br>
 
 
        <div class="form-group">
          <label for="email">Email<span class="text-danger"> *</span></label> 
          <span *ngIf="f['email'].touched && f['email'].hasError('required')" class="form-error-message">
            <b>!email is required</b> 
         </span>  
         <span *ngIf="f['email'].touched && f['email'].hasError('email')" class="form-error-message">
          <b>! Email must be a valid email address
          </b> 
        </span>
          <input
          formControlName="email"
            type="email"
            class="form-control"
            name="email"
            placeholder="user's name"
          />
          <div>
    
          </div>
        </div>
        <br>
        <div class="form-group">
          <label for="password">Password<span class="text-danger"> *</span></label> 
          <span *ngIf="f['password'].touched && f['password'].hasError('minlength')" class="form-error-message">
            <b>Password is require</b> 
         </span>
          <span *ngIf="f['password'].touched && f['password'].hasError('minlength')" class="form-error-message">
            <b>!Password must be at least 6 characters</b> 
         </span> 
          <input
          formControlName="password"
            type="password"
            class="form-control"
            name="password"
            required=""
            placeholder="Password" 
            />
          <div>
                    
          </div>
        </div>    
       </mat-dialog-content>
    </div>   

        <mat-dialog-actions>
            <div class="form-group">
              <button [disabled]="signUpFrom.invalid" class="btn btn-primary btn-block">Sign Up</button>
           </div>
           <div class="alert alert-warning" *ngIf="signUpFrom.invalid && isSignUpFailed">
               Signup failed!<br />
          </div>
        </mat-dialog-actions>
      </form>
  
      <div class="alert alert-success" *ngIf="isSuccessful">
        Your registration is successful!
      </div>
   </div>
  
</div>
  