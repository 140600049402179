import { Component, OnInit } from '@angular/core';

import {MenuItem} from '../../interfaces/menu-item';

@Component({
  selector: 'app-sidenav-menu-items',
  templateUrl: './sidenav-menu-items.component.html',
  styleUrls: ['./sidenav-menu-items.component.scss']
})
export class SidenavMenuItemsComponent implements OnInit {

  menuItems: MenuItem[] = [];

  constructor() { }

  ngOnInit(): void {
    this.menuItems = [
      {id: 1, name: 'Item', routerLink: ['/items'], matIcon: 'item'},
      {id: 2, name: 'Category', routerLink: ['items/category'], matIcon: 'category'},
      {id: 4, name: 'Dashboard', routerLink: ['/items/dashboard'], matIcon: 'dashboard'},
      {id: 5, name: 'Sales', routerLink: ['/items/sales'], matIcon: 'money'}

    ];
  }
}
