import { Component, OnInit } from '@angular/core';
import moment from "moment";

import { DatePipe } from '@angular/common';
import { CategoryService } from 'src/app/services/category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SaleCategory } from 'src/app/interfaces/SalesCategory';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  providers: [DatePipe]

})
export class ChartComponent implements OnInit {
  view: [number,number]=[200,150];
  typeChart: string ='pie';
  chart:string='';
  today = new Date();
   saleData : SaleCategory[]=[];


   constructor(private categoryService:CategoryService, private formBuilder:FormBuilder){}

  ngOnInit(): void {
    this.categoryService.getAllCategorySale('','').subscribe(
      (resp: SaleCategory[])=>{this.saleData=resp;});
  }
  title = 'barchartApp';

  dateForm= this.formBuilder.group({
    startDate: ['',[Validators.required]],
    endDate: ['', [Validators.required]]
  },
  {validators:this.customValidatorGroup()}); 
  currentDate = this.dateForm.get('startDate')?.value;
  priviousDate = this.dateForm.get('endDate')?.value;

  customValidatorGroup()
  {
    return (form:FormGroup)=>{
        const min=form.get('fomDate');
        const max=form.get('toDate');
        return min?.value && max?.value && max.value.toDate()<min.value.toDate()?{error:'min malue'}:null
    }
  }

 getSales(start:string, end:string){
  this.categoryService.getAllCategorySale(start,end).subscribe(
    (resp: SaleCategory[])=>{this.saleData=resp;});
 }
}
