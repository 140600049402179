import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { CurrencyPipe } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ItemListComponent } from './public/item-list/item-list.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ItemListSummaryComponent } from './public/item-list-summary/item-list-summary.component';
import { ItemsComponent } from './public/items/items.component';
import { SidenavMenuItemsComponent } from './shared/sidenav-menu-items/sidenav-menu-items.component';
import { AddItemComponent } from './public/add-item/add-item.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { ItemInsertComponent } from './public/item-insert/item-insert.component';
import { ItemSellComponent } from './public/item-sell/item-sell.component';
import { ItemUpdateComponent } from './public/item-update/item-update.component';
import { ItemDeleteComponent } from './public/item-delete/item-delete.component';
import { ItemActionsComponent } from './public/item-actions/item-actions.component';
import { ItemActionDeleteConfirmationComponent } from './public/item-action-delete-confirmation/item-action-delete-confirmation.component';
import { ItemDetailsFormComponent } from './public/item-details-form/item-details-form.component';
import { CategoryComponent } from './public/category/category.component';
import { SalesComponent } from './public/sales/sales.component';
import { AddCategoryComponent } from './public/add-category/add-category.component';
import { ListCategoryComponent } from './public/list-category/list-category.component';
import { CategoryDeleteComponent } from './public/category-delete/category-delete.component';
import { CategoryActionComponent } from './public/category-action/category-action.component';
import { CategoryUpdateComponent } from './public/category-update/category-update.component';
import { CategoryDetailsComponent } from './public/category-details/category-details.component';
import { ChartComponent } from './public/chart/chart.component';
import { FormsModule } from '@angular/forms';
import { NgxChartsModule }from '@swimlane/ngx-charts';  // added
import 'hammerjs';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { SaleDetailsFormComponent } from './public/sale-details-form/sale-details-form.component';
import { ItemMenuComponent } from './public/item-menu/item-menu.component';
import { HomeComponent } from './public/home/home.component';
import { SecurityModule } from './public/security/security.module';
import { MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    AppComponent,
    ItemListComponent,
    NavbarComponent,
    ItemListSummaryComponent,
    ItemsComponent,
    SidenavMenuItemsComponent,
    AddItemComponent,
    PageNotFoundComponent,
    ItemInsertComponent,
    ItemSellComponent,
    ItemUpdateComponent,
    ItemDeleteComponent,
    ItemActionsComponent,
    ItemActionDeleteConfirmationComponent,
    ItemDetailsFormComponent,
    CategoryComponent,
    SalesComponent,
    AddCategoryComponent,
    ListCategoryComponent,
    CategoryDeleteComponent,
    CategoryActionComponent,
    CategoryUpdateComponent,
    CategoryDetailsComponent,
    ChartComponent,
    DashboardComponent,
    SaleDetailsFormComponent,
    ItemMenuComponent,
    HomeComponent,
  
  ],
    imports: [
       AppRoutingModule,
        BrowserModule,
        FormsModule ,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        MatGridListModule,
        MatButtonModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        HttpClientModule,
        MatBottomSheetModule,
        MatDialogModule ,
        MatTabsModule,
        NgxChartsModule,
        MatSelectModule,
        SecurityModule,
        MatMenuModule,
 
    ],
  providers: [
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
