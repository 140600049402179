<form [formGroup]="categoryForm">

    <mat-card-title>{{data.categoryActionTitle}}</mat-card-title>
  
    <mat-divider></mat-divider>
  
    <br />
  
    <div class="mat-action-row">
      <mat-form-field appearance="outline">
        <mat-label>Id</mat-label>
        <input matInput placeholder="e.g. SM-00001" formControlName="id" [readonly]="true" required>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="e.g. Redmi Note 8 Pro" formControlName="name" [readonly]="data.readonlyFields.name" required>
      </mat-form-field>
    </div>
  
    <br />
  
    <div class="mat-action-row">
      <mat-form-field appearance="outline">
        <mat-label>Comment</mat-label>
        <input matInput placeholder="Comment" formControlName="comment" [readonly]="data.readonlyFields.comment">
      </mat-form-field>
    </div>
    <br />
  
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button color="primary"
        [disabled]="!categoryForm.valid"
        [mat-dialog-close]="categoryForm.value" cdkFocusInitial>{{data.submitButtonLabel}}</button>
    </mat-dialog-actions>
  
  </form>
  