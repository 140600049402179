import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginDto, SignUpDto } from './dto';

const AUTH_API = 'http://localhost:8080/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
 export class AuthenticationData{
  accessToken?:string;
  user?:User;
 }
 export class User {
  public id!: number;
  public username!: string;
  public email!: String;

 }

 export class Role {
  public id!: number;
  public name!: string;

 }
 
export enum ERole {
  ROLE_USER,
  ROLE_MODERATOR,
  ROLE_ADMIN
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject(false);

  constructor(private http: HttpClient) { }

  login(loginDto:LoginDto): Observable<AuthenticationData> {
    return this.http.post(AUTH_API + 'signin', loginDto);
  }

  register(signUpDto: SignUpDto): Observable<any> {
    return this.http.post(AUTH_API +'signup', signUpDto, httpOptions);
  }
  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(AUTH_API + 'roles');
  }

}


