import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from '../interfaces/Category';
import { Observable } from 'rxjs/internal/Observable';
import { CategoryEventListenerService } from './category-event-listener.service';
import { catchError, map, tap } from 'rxjs/operators';
import { ObservableInput, of } from 'rxjs';
import { CategoryResponse } from '../interfaces/Category-response';
import { SaleCategory } from '../interfaces/SalesCategory';
import { BACKEND } from '../config';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  url = BACKEND.HOST + '/api';
  constructor(
    private http: HttpClient, 
    private categoryEventListenerService: CategoryEventListenerService
  
  ) { }

  getCategories(): Observable<CategoryResponse[]> {
    return this.http.get<CategoryResponse>(this.url+"/categories")
      .pipe(
        
        catchError(error => this.handleError(error))
      );
  }
  getAllCategorySale(startDate:string, endDate:string):Observable<SaleCategory[]>{
    let params = new HttpParams().set("startDate", startDate).set("endDate",endDate);
    return this.http.get<SaleCategory>(this.url+"/category-sale",{params:params})
      .pipe(
        
        catchError(error => this.handleError(error))
      );
  }
  addCategory(category :Category): Observable<Category>{
    return this.http.post<Category>(this.url+"/category", category)
      .pipe(
        tap(response => this.categoryEventListenerService.onAdded(category)),
        catchError(error => this.handleError(error))
      );
  }
  deleteCategory(url :string): Observable<{}> {
    return this.http.delete<{}>(url)
      .pipe(
        tap(response => this.categoryEventListenerService.onDeleted()),
        catchError(error => this.handleError(error))
      );
  }

  handleError(error: any): ObservableInput<any> {
    console.log(error);
    this.categoryEventListenerService.onFailure(error);
    return of([]);
  }

  updateCategory( category: Category): Observable<Category> {
    return this.http.put<Category>(this.url, category)
      .pipe(
        tap(response => this.categoryEventListenerService.onUpdated(category)),
        catchError(error => this.handleError(error))
      );
  }
}
