<form [formGroup]="itemForm">

    <mat-card-title>{{data.itemActionTitle}}</mat-card-title>
  
    <mat-divider></mat-divider>
  
    <br />
  
    <div class="mat-action-row">
      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput placeholder="e.g. SM-00001" formControlName="code" [readonly]="data.readonlyFields.code" required>
      </mat-form-field>
  
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="e.g. Redmi Note 8 Pro" formControlName="name" [readonly]="data.readonlyFields.name" required>
      </mat-form-field>
    </div>
    <br />
  
    <div class="mat-action-row">
      <mat-form-field appearance="outline">
        <mat-label>Cost</mat-label>
        <input matInput placeholder="Cost" formControlName="cost" [readonly]="data.readonlyFields.cost" required>
      </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Price</mat-label>
          <input matInput placeholder="e.g. $000 " formControlName="price" [readonly]="data.readonlyFields.price" required>
        </mat-form-field>
    </div>
    <br />
    <br />
  
    <div class="mat-action-row">
        <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <input matInput placeholder="e.g. Redmi Note 8 Pro" formControlName="category" [readonly]="data.readonlyFields.category" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Quantity</mat-label>
            <input matInput placeholder="Quantity" formControlName="quantity" [readonly]="data.readonlyFields.quantity" required>
        </mat-form-field>
    </div>
    <br />
  
    <mat-dialog-actions align="end">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button color="primary"
        [disabled]="!itemForm.valid"
        [mat-dialog-close]="itemForm.value" cdkFocusInitial>{{data.submitButtonLabel}}</button>
    </mat-dialog-actions>
  
  </form>
  