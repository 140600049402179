<mat-tab-group dynamicHeight>
  <mat-tab label="Data Visualization">
    <div >
      <br/>
      <app-chart></app-chart>
    </div>
  </mat-tab>
  <mat-tab label="Others Statistics">
    <div >
      <br/>
Statistics
    </div>
  </mat-tab>
</mat-tab-group>
