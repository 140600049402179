<mat-toolbar color="primary">LogIn<mat-icon >person</mat-icon> </mat-toolbar>
<mat-card>
    <mat-card-content>
      <div class="example-container">
        <form
            class="example-form"
            [formGroup]="signInFrom" 
            (ngSubmit)="onSubmit(signInFrom.value)">
            
                <mat-form-field appearance="outline">
                  <mat-label>Enter your email</mat-label>
                  <input autocomplete="email" type="email" matInput placeholder="pat@example.com" [formControl]="email" required>
                  <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Enter your password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="password.invalid">{{getErrorMessage()}}</mat-error>

                </mat-form-field>     
        </form>
      </div> 
    </mat-card-content>
    </mat-card>

    <mat-dialog-actions>
        <div class="example-button-row">
            
            <button type="submit" (click)="onSubmit(signInFrom.value)">
              Login
            </button>
        </div>
            <div class="alert alert-danger"role="alert" *ngIf="f.submitted && isLoginFailed"> Login failed </div>
        </mat-dialog-actions>
     







