<router-outlet></router-outlet> 
<br />
<br />
<!-- 


<mat-sidenav-container>
  <mat-sidenav mode="side" opened>
    <app-sidenav-menu-items></app-sidenav-menu-items>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-container class="is-phone-portrait">
      <router-outlet></router-outlet>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
-->
