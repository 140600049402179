import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Category } from 'src/app/interfaces/Category';
import { CategoryEventListenerService } from 'src/app/services/category-event-listener.service';
import { CategoryService } from 'src/app/services/category.service';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  categoryActionTitle = 'Add Category';
  submitButtonLabel = 'Add';

  constructor(
        private formBuilder: FormBuilder,
        private categoryEventListenerService: CategoryEventListenerService,
        private categoryService: CategoryService,
        private snackbarService: SnackbarService,

    ) { 
      this.subscribeForCategoryEvents();

  }
  textInputValidators = [Validators.required, Validators.maxLength(100)];

  categoryForm = this.formBuilder.group({
    name: ['', this.textInputValidators],
    comment: ['', Validators.maxLength(100)]
  });

  ngOnInit(): void {
  }

  subscribeForCategoryEvents(): void {
    this.categoryEventListenerService.categoryEventAddEmit$.subscribe(category => {
      this.onAdded(category);
    });
    this.categoryEventListenerService.categoryEventFailureEmit$.subscribe(error => {
      this.onFailure(error);
    });
  }

  
  onAdd(): void {
    this.categoryService.addCategory(this.categoryForm.value)
      .subscribe();
  }

  onAdded(category: Category): void {
    this.snackbarService.openSnackBar('Category Added Successfully!');
  }

  onFailure(error: any): void {
    this.snackbarService.openSnackBar('Request Failed!');
  }

}
