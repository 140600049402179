<div>
  <form name="myForm" ng-controller="DateController as dateCtrl" [formGroup]="dateForm" (ngSubmit)="getSales(this.dateForm.get('startDate')?.value,this.dateForm.get('endDate')?.value)">
    <label for="startDate">From :</label>
    <input 
    type="date" 
    id="startDate"
    name="input" 
    ng-model="example.value"
    placeholder="yyyy-MM-dd" 
    min="{{priviousDate}}"
    max="{{currentDate}} "
    formControlName="startDate"
    required />
    &nbsp; &nbsp;
    <label for="exampleInput">To :</label>
     <input 
     type="date" 
     id="exampleInput"
     name="input" 
     ng-model="example.value"
     placeholder="yyyy-MM-dd" 
     min="{{priviousDate}}"
     max="{{currentDate}} "
     formControlName="endDate"
     required />
    
    <button
      mat-raised-button color="primary"
      [disabled]="!dateForm.valid">ok</button>

    <div role="alert">
      <span class="error" ng-show="myForm.input.$error.required">
        </span>
      <span class="error" ng-show="myForm.input.$error.date">
       </span>
     </div>
  </form>
</div>

<div>
<br>
</div>
  
  <div>
    <ngx-charts-bar-vertical 
    [results]="saleData"
    [view]=" [700,300]"
    [xAxisLabel]="'Products'"
    [legendTitle]="'Product Sale Chart'"
    [yAxisLabel]="'Sale'"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [xAxis]="true"
    [yAxis]="true"
    [gradient]="true">
  </ngx-charts-bar-vertical>
  </div>