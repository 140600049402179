<mat-dialog-content class="mat-typography">

  <mat-card-title>Delete Item</mat-card-title>

  <mat-divider></mat-divider>

  <br />

  <ng-container>
    Item history of this item will also be deleted.
  </ng-container>
  <br />
  <ng-container>
    Are you sure you want to delete this item ?
  </ng-container>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>No, Cancel</button>
  <button mat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Yes, Delete</button>
</mat-dialog-actions>
