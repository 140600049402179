import { Component, OnInit } from '@angular/core';
import { TreeMapModule } from '@swimlane/ngx-charts';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackbarService } from './services/snackbar/snackbar.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './public/security/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  // Todo Fetch title from the configurations through HTTP Client
  title = 'Client';
constructor(){}

}
