<form [formGroup]="categoryForm" (ngSubmit)="onAdd()">

  <mat-card-title>{{itemActionTitle}}</mat-card-title>

  <mat-divider></mat-divider>

  <br />
  <div class="mat-action-row">
    
    <mat-form-field appearance="outline">
      <mat-label>Code</mat-label>
      <input matInput placeholder="e.g. SM-00001" formControlName="code" >
    </mat-form-field>
  
    <mat-form-field appearance="outline">
      <mat-label>Category</mat-label>
     <mat-select [(value)]="selectedCategory" formControlName="category" >
        <mat-option *ngFor=" let cat of categories" value ="{{cat.id}}">{{cat.name}}</mat-option>
     </mat-select>
  </mat-form-field>
  </div>
  <br />

  <div class="mat-action-row">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput placeholder="e.g. Redmi Note 8 Pro" formControlName="name" required>
    </mat-form-field>
 
    <mat-form-field appearance="outline">
      <mat-label>Cost</mat-label>
      <input matInput placeholder="Cost" formControlName="cost" required>
    </mat-form-field>
  </div>
  <br />
  <div class="mat-action-row">
    <mat-form-field appearance="outline">
      <mat-label>Quantity</mat-label>
      <input matInput placeholder="Quantity" formControlName="quantity" required>
    </mat-form-field>
  </div>
  <br />

  <ng-container>
    <button mat-stroked-button>Cancel</button>    &nbsp;    &nbsp;

    <button
      mat-raised-button color="primary"
      [disabled]="!categoryForm.valid">{{submitButtonLabel}}</button>
  </ng-container>

</form>
