<div></div>
<div >
    <form align="center" 
     name="myForm" ng-controller="DateController as dateCtrl" 
    [formGroup]="dateForm" (ngSubmit)="getSales(this.dateForm.get('startDate')?.value,this.dateForm.get('endDate')?.value)">
        <label for="startDate">From :</label>
        <input 
        type="date" 
        id="startDate"
        name="input" 
        ng-model="example.value"
        placeholder="yyyy-MM-dd" 
        min="{{priviousDate}}"
        max="{{currentDate}} "
        formControlName="startDate"
        required />
        &nbsp; &nbsp;
        <label for="exampleInput">To :</label>
         <input 
         type="date" 
         id="exampleInput"
         name="input" 
         ng-model="example.value"
         placeholder="yyyy-MM-dd" 
         min="{{priviousDate}}"
         max="{{currentDate}} "
         formControlName="endDate"
         required />
         &nbsp; 
        <button class="button"
          color="primary"
          [disabled]="!dateForm.valid">ok
        </button>
    
        <div role="alert">
          <span class="error" ng-show="myForm.input.$error.required">
            </span>
          <span class="error" ng-show="myForm.input.$error.date">
           </span>
         </div>
      </form>

</div>
<mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="e.g. Redmi Note 8 Pro" #input>
  </mat-form-field>
  
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
  
      <!-- ID Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
        <td mat-cell *matCellDef="let sale"> {{sale.code}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let sale"> {{sale.name}} </td>
      </ng-container>
 
      <!-- Qualumn -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> date </th>
        <td mat-cell *matCellDef="let sale"> {{sale.date}} </td>
      </ng-container>

       <!-- Quantity Column -->
       <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price</th>
        <td mat-cell *matCellDef="let sale"> {{sale.price}} </td>
      </ng-container>
  
      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity</th>
        <td mat-cell *matCellDef="let sale"> {{sale.quantity}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let sale; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No items found!</td>
      </tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  

