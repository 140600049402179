<div >

<mat-toolbar color="accent">

<mat-toolbar-row>
    <div >
      <img class="icon"
       width="95" src="assets/images/logo.ico" />
    </div>
 
    <button mat-button><a> </a></button>

    <button mat-button [matMenuTriggerFor]="category">Config</button>
    <mat-menu #config="matMenu">
      <button  mat-menu-item><a (click)="showDialog()"> New User</a></button>
      <button mat-menu-item><a (click)="getComponent('category')"> Add/List Category </a>
      </button>
    </mat-menu>


  <button mat-button [matMenuTriggerFor]="category">Category</button>
<mat-menu #category="matMenu">
  <button mat-menu-item><a  (click)="getComponent('category')"> Add/List Category </a>
  </button>
</mat-menu>

<button mat-button [matMenuTriggerFor]="item">Items</button>
<mat-menu #item="matMenu">
  <button mat-menu-item><a  (click)="getComponent('addItem')"> Add Item(s) </a></button>
  <button mat-menu-item> <a (click)="getComponent('listItem')">Edit/Sale Item(s) </a>
  </button>
</mat-menu>

<button mat-button><a (click)="getComponent('dashboard')">Dashboard </a></button>
<button mat-button><a (click)="getComponent('sales')">Sales </a></button>

<span class="example-spacer"></span>

<button mat-button >
  <mat-icon>power_settings_new</mat-icon>
   <span (click)="logout()">Logout</span>
</button>
<mat-icon>perm_identity</mat-icon>
<h6 style="font-size:1vw">{{userName}}</h6>
</mat-toolbar-row>
</mat-toolbar>

</div>

<div>
  <mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened class="side">
      <button mat-button><a (click)="getComponent('dashboard')">Dashboard </a></button><br>
      <button mat-button><a (click)="getComponent('sales')">Sales </a></button><br>
      <button mat-button><a (click)="getComponent('user')">Add App User </a></button>


    </mat-drawer>
    <mat-drawer-content>

  <mat-card class="example-card">
    <span *ngIf="val==='init'"><app-item-list></app-item-list></span>

<span *ngIf="val==='dashboard'" >
      <app-dashboard></app-dashboard>
</span>
<span *ngIf="val==='category'" >
  <app-category></app-category>
</span>
<span *ngIf="val==='addItem'" >
  <app-add-item></app-add-item>
</span>
<span *ngIf="val==='listItem'" >
  <app-items></app-items>
</span>
<span *ngIf="val==='sales'" >
  <app-sales></app-sales>
</span>
<span *ngIf="val==='user'" >
  <app-register></app-register>
</span>

</mat-card>
</mat-drawer-content>
</mat-drawer-container>

</div>




  