import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  activateMenuBar = new BehaviorSubject<boolean>(true);
  isActivateMenuBar$ = this.activateMenuBar.asObservable();

  constructor(private matSnackBar: MatSnackBar) { }

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';



  openSnackBar(message: string): void {
    this.matSnackBar.open(message, 'Dismiss', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }
}
