import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-action-delete-confirmation',
  templateUrl: './item-action-delete-confirmation.component.html',
  styleUrls: ['./item-action-delete-confirmation.component.sass']
})
export class ItemActionDeleteConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
