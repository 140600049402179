import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../security/token-storage.service';
import { User } from '../security/auth.service';
import { Router } from '@angular/router';
import { RegisterComponent } from '../security/register/register.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-item-menu',
  templateUrl: './item-menu.component.html',
  styleUrls: ['./item-menu.component.scss']
})
export class ItemMenuComponent implements OnInit {
   userName :string = "";
   val :string = "init";

  constructor(private tokenStorage: TokenStorageService, private router: Router,public dialog: MatDialog,) { }

  ngOnInit(): void {
    const user:User =this.tokenStorage.getUser();
     this.userName = user.username;
 
  }

logout(){
  this.tokenStorage.logout();
  this.router.navigateByUrl('home')
}
getComponent(component:string){
  this.val=component
}


showDialog(){
  const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
  
    this.dialog.open(RegisterComponent,{ 
      autoFocus: false,
                    width: '460px',
                     height: '380px',
                    panelClass: 'custom-dialog-container'}
    );
  
 

}
}
