<form [formGroup]="categoryForm" (ngSubmit)="onAdd()" >

    <mat-card-title>
      {{categoryActionTitle}}
    </mat-card-title>
  
    <br />
<div align="center">
    <div class="mat-action-row">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="e.g. Redmi Note 8 Pro" formControlName="name" required>
      </mat-form-field>
    </div>

    <br />
    <div class="mat-action-row">
      <mat-form-field appearance="outline">
        <mat-label>Comment</mat-label>
        <input matInput placeholder="e.g. Redmi Note 8 Pro" formControlName="comment">
      </mat-form-field>
 
    </div>  
    <br />
  
    <ng-container>
      <button mat-stroked-button>Cancel</button>
      <button
        mat-raised-button color="primary"
        [disabled]="!categoryForm.valid">{{submitButtonLabel}}</button>
    </ng-container>
    
  </div>

</form>
  