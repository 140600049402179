import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BACKEND } from 'src/app/config';
import { Category } from 'src/app/interfaces/Category';
import { CategoryService } from 'src/app/services/category.service';
import { ItemActionDeleteConfirmationComponent } from '../item-action-delete-confirmation/item-action-delete-confirmation.component';

@Component({
  selector: 'app-category-delete',
  templateUrl: './category-delete.component.html',
  styleUrls: ['./category-delete.component.scss']
})
export class CategoryDeleteComponent implements OnInit {

  @Input() category!: Category;

  constructor(
    private categoryService: CategoryService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onDelete(category: Category): void {
    const dialogRef = this.matDialog.open(ItemActionDeleteConfirmationComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const url = BACKEND.HOST + '/api/category/' + category.id;
        this.categoryService.deleteCategory(url)
          .subscribe();
      }
    });
  }

}
