import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Category } from 'src/app/interfaces/Category';
import { CategoryResponse } from 'src/app/interfaces/Category-response';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['id', 'name', 'quantityOrder','quantitySold','comment', 'actions'];
  private categories: CategoryResponse[] = [];
  dataSource = new MatTableDataSource<CategoryResponse>(this.categories);

  constructor(
    private categoryService: CategoryService
  ) { }
  ngOnInit(): void {
    this.fetchCategories();
  }


  fetchCategories(): void {
    this.categoryService.getCategories()
      .subscribe((categories: CategoryResponse[]) => {
        this.categories = categories;
        this.updateTableDataSource();
      });
  }

  updateTableDataSource(): void {
    this.dataSource = new MatTableDataSource<CategoryResponse>(this.categories);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
