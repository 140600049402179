<mat-tab-group dynamicHeight>
    <mat-tab label="Add Category">
      <div >
        <br/>
        <app-add-category></app-add-category>
      </div>
    </mat-tab>
    <mat-tab label="List Categories">
      <div >
        <br/>
        <app-list-category></app-list-category>
      </div>
    </mat-tab>
  </mat-tab-group>
  