<mat-toolbar color="accent">
    <img class="icon"
    width="125PX" src="assets/images/logo.ico" />
    <span class="example-spacer"></span>
    <!--  <button  aria-hidden="true" (click)="showDialog('register')">
    <mat-icon> login</mat-icon>AddUser</button>
    &nbsp;&nbsp;&nbsp;
     --> 
    <button pButton  type="button"  (click)="showDialog('login')">
    <mat-icon >person</mat-icon>Login </button>
    </mat-toolbar>
  
       
  <!--  </button>
    
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
     <mat-icon>share</mat-icon>
    </button>    </mat-toolbar>

    <button mat-button *ngFor="let item of menuItems" fxHide.xs>
        <mat-icon class="mr">{{item.matIcon}}</mat-icon>
        {{item.name}}
    </button>   
       --> 

<div >
    <img  src="assets/images/sneaker.png" class="rotate" width="200" height="200"  />
</div>
